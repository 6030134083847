<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">입/출고일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range type="lookup-condition" v-model="searchFilter.dateRange"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">창고</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect v-model="searchFilter.wrhous"
                                 :dataSource="commonCodeOptions.wrhousData"
                                 :fields="commonCodeOptions.fields"
                                 :allowFiltering="false"
                                 cssClass="lookup-condition-multiselect"
                                 width="300"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">상품명</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchFilter.productName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button button-div="FILE" :ignore="isPopupOpened" @click.native="onClickExcel">Excel</erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText.vue";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import moment from "moment/moment";
import {getFormattedDate} from "@/utils/date";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import StockProductStat from "@/api/v2/StockManage/StockProductStat";

export default {
  name: "ProductWrhsdlvrStatus",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    ReportView,
    ErpButton,
    EjsGridWrapper,
    InputText,
    InputDateRange,
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //조회 필드
      searchFilter: {
        dateRange: {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
        wrhous: [], //다중선택된 창고코드

        fromDate: null,
        toDate: null,
        wrhousList: null, //다중선택된 창고코드 조회값
        productName:null,
      },

      //드롭다운 코드
      commonCodeOptions: {
        wrhousData: [],
        wrhousngDiv: commonCodesGetCommonCode("WRHOUSNG_DIV",false,true,"",""),
        fields: { text: "comName", value: "comCode" },
      },

      //그리드 필드
      gridField: {
        title: "입/출고 현황 목록",
        count : 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return false;
    },
    initGrid() {
      return {
        columns: [
          { field: "historyId",       textAlign: 'center', allowEditing: false, width: 70,   visible: false, type: "string",  headerText: "입출고번호", isPrimaryKey: true, },
          { field: "historyDiv",      textAlign: 'center', allowEditing: false, width: 70,   visible: true,  type: "string",  headerText: "구분", },
          { field: "historyRemarks",  textAlign: 'center', allowEditing: false, width: 70,   visible: true,  type: "string",  headerText: "타입"},
          { field: "historyDate",     textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "입/출고일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "wrhousCode",      textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "창고", isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          { field: "inWrhous",        textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "입고창고", isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          { field: "productCode",     textAlign: 'center', allowEditing: false, width: 100,  visible: false, type: "string",  headerText: "상품코드", },
          { field: "productName",     textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "상품", },
          { field: "unit",            textAlign: 'center', allowEditing: false, width: 50,   visible: true,  type: "string",  headerText: "단위", },
          { field: "qty",             textAlign: 'right',  allowEditing: false, width: 100,  visible: true,  type: "number",  headerText: "수량", isNumericType: true,},
          { field: "remarks",         textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "비고", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 버튼 이벤트 *******************************
    //기초데이터 세팅
    async initData() {
      //창고콤보(권한있는 상품창고만) --이력이니 삭제된것도
      //this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'Y',authoFlag:'Y',suppliesFlag:'N'});
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'N' ,authoFlag:'N',suppliesFlag:'N'});
    },
    //조회
    async onClickSearch() {
      // 조회조건에 있는 창고만(권한창고)
      this.searchFilter.wrhousList = this.searchFilter.wrhous.length > 0 ? this.searchFilter.wrhous.join('|') : this.commonCodeOptions.wrhousData.map(data => data.comCode).join('|');
      this.searchFilter.fromDate = this.searchFilter.dateRange.from;
      this.searchFilter.toDate  = this.searchFilter.dateRange.to;
      // 창고 array,날짜 객체 삭제.
      const args = Object.assign({...this.searchFilter},{wrhous:null, dateRange:null});
      this.gridField.dataSource = await StockProductStat.getProductStockHistory(args);
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //리포트 출력
    onClickPrint() {
    },
    //endregion
    //region ******************************* 상세 이벤트 *******************************
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 10px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 10px); margin:0}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}
</style>
